var md = new MobileDetect(window.navigator.userAgent);
var ua = detect.parse(navigator.userAgent);

var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;

var ismobile = false;
var openMega = null;
var currentPage = 1;

if (md.mobile())
    ismobile = true;


(function () {
    "use strict";

    window.addEventListener("scroll", function(e) {
      animateNav();
    });

})();

function animateNav() {
  var nav = document.querySelector('body #nav');
  if (!nav) {return};

  var pos = window.scrollY;

  if(pos >= 100) {
    nav.classList.add('thin');
  } else {
    nav.classList.remove('thin');
  }
}

// browser-warning
if (ua.browser.family === "IE") {
    const warning = document.querySelectorAll('.browser-warning');
    warning[0].className = 'browser-warning show';
}
